<template>
  <router-view></router-view>
</template>
<script>
  export default {
    props: ['type'],
    watch: {
      type() {
        this.setWorkflowTicketType()
      }
    },
    methods: {
      setWorkflowTicketType() {
        switch (this.type) {
          case 'allPcTickets':
          case 'desktopDeployments':
          case 'laptopDeployments':
          case 'pcAccessories':
          case 'pcRepairs':
          case 'pcUserChanges':
          case 'pcUserChangesOnly':
          case 'pcOsRefreshes':
          case 'pcReturns':
            this.$store.commit('graphql/setPcWorkflowTicketType', this.type)
            this.$store.commit('graphql/setWorkflowTicketType', 'pcWorkflowTickets')
            break
          case 'allMobileTickets':
          case 'mobileDeployments':
          case 'mobileReturns':
          case 'mobileUserChanges':
          case 'mobileSimChanges':
            this.$store.commit('graphql/setMobileWorkflowTicketType', this.type)
            this.$store.commit('graphql/setWorkflowTicketType', 'mobileWorkflowTickets')
            break
          default:
            break
        }        
      }
    },
    created() {
      this.setWorkflowTicketType()
    }
  }
</script>